import React, { useLayoutEffect } from 'react'
import { connect } from 'react-redux'
import Layout from '../components/layout'

const applicationSettings = {
  clientId: '384fNwGKoPBzUuQPTRaprXo6sFfKy98t',
  baseUrl: 'https://widgets.shadow.gg',
  adConfiguration: [
    {
      client: 'ca-pub-3373276592006817',
      slot: '6052321469',
      space: 'match-score',
      format: 'auto',
    },
  ],
}

export const widgetScript = () => `
(function (w, d, s) {
    if (w.SDW) console.error('SDW widget already included');
    w.SDW = {}, w.SDW._r = s; methods = ['mount', 'unmount', 'addWidget', 'updateWidget', 'removeWidget'];
    w.SDW._q = []; methods.forEach(method => w.SDW[method] = function () { w.SDW._q.push([method, arguments]) });
    var script = d.createElement('script'); script.async = 1; script.src = s;
    var before = d.getElementsByTagName('script')[0]; before.parentNode.insertBefore(script, before);
})(window, document, '${'https://widgets.shadow.gg/realtime/scriptLoader.js'}');
window.SDW.mount(${JSON.stringify(applicationSettings)});
window.SDW.addWidget({
    containerId: 'custom-shadow',
    settings: { hasPlayerStatsOverlay: true, hasDesktopLayout: true, userDidConsent: true},
    type: 'calendar',
});
`

const WidgetPage = ({ declarePageAsNotLanding }) => {
  declarePageAsNotLanding()

  useLayoutEffect(() => {
    if (!window.SDW) {
      const script = document.createElement('script')
      script.setAttribute('id', 'sdw-script')
      script.innerHTML = widgetScript()
      script.async = false
      document.body.appendChild(script)
    } else {
      window.SDW.mount(applicationSettings)
      window.SDW.addWidget({
        containerId: 'custom-shadow',
        settings: {
          hasPlayerStatsOverlay: true,
          hasDesktopLayout: true,
          userDidConsent: true,
        },
        type: 'calendar',
      })
    }

    return () => {
      if (window.SDW) {
        window.SDW.unmount()
      }
    }
  }, [])

  return (
    <Layout>
      <div id='custom-shadow' />
      <div
        style={{
          height: '120px',
          width: '100%',
          backgroundColor: 'var(--sdw-background)',
        }}
      />
    </Layout>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    declarePageAsNotLanding: () => dispatch({ type: `SET_LANDING_PAGE_FALSE` }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetPage)

import React, { useEffect, useLayoutEffect } from 'react'
import * as styles from './styles.module.scss'
import classnames from 'classnames'

const hTickerSettings = {
  clientId: 'HzDVkZUw4JNznD5d6oR4izVmuYuNc5Cm',
  baseUrl: 'https://widgets.shadow.gg',
  theme: {
    navbar: '#00c7bc',
  },
}

// const vTickerSettings = {
//   clientId: 'VzDVkZUw4JNznD5d6oR4izVmuYuNc5Cm',
//   baseUrl: 'https://widgets.shadow.gg',
// }

export const widgetScript = () => `
(function (w, d, s) {
    if (w.SDW) console.error('SDW widget already included');
    w.SDW = {}, w.SDW._r = s; methods = ['mount', 'unmount', 'addWidget', 'updateWidget', 'removeWidget'];
    w.SDW._q = []; methods.forEach(method => w.SDW[method] = function () { w.SDW._q.push([method, arguments]) });
    var script = d.createElement('script'); script.async = 1; script.src = s;
    var before = d.getElementsByTagName('script')[0]; before.parentNode.insertBefore(script, before);
})(window, document, '${'https://widgets.shadow.gg/realtime/scriptLoader.js'}');
window.SDW.mount(${JSON.stringify(hTickerSettings)});
window.SDW.addWidget({
    containerId: 'shadow-h-ticker',
    settings: { hasPlayerStatsOverlay: true, hasDesktopLayout: true, userDidConsent: true, tickerOnly: true, sdwBranding: true },
    type: 'topbar',
});`
// window.SDW.addWidget({
//   containerId: 'shadow-v-ticker',
//   settings: { hasPlayerStatsOverlay: true, hasDesktopLayout: true, userDidConsent: true, tickerOnly: true, sdwBranding: true },
//   type: 'calendar',
// });
// `

const Ticker = () => {
  useLayoutEffect(async () => {
    if (!window.SDW) {
      const script = document.createElement('script')
      script.setAttribute('id', 'sdw-script')
      script.innerHTML = widgetScript()
      script.async = false
      document.body.appendChild(script)
    } else {
      window.SDW.mount(hTickerSettings)
      // window.SDW.mount(vTickerSettings)
      window.SDW.addWidget({
        containerId: 'shadow-h-ticker',
        settings: {
          hasPlayerStatsOverlay: true,
          hasDesktopLayout: true,
          userDidConsent: true,
          tickerOnly: true,
          sdwBranding: true,
        },
        type: 'topbar',
      })
      await new Promise(resolve => setTimeout(resolve, 500))
      window.SDW.addWidget({
        containerId: 'shadow-v-ticker',
        settings: {
          hasPlayerStatsOverlay: true,
          hasDesktopLayout: true,
          userDidConsent: true,
          tickerOnly: true,
          sdwBranding: true,
        },
        type: 'calendar',
      })
    }

    return () => {
      if (window.SDW) {
        window.SDW.unmount()
      }
    }
  }, [])

  useEffect(() => {
    const existingScript = document.getElementById('formScript')
    if (!existingScript) {
      const script = document.createElement('script')
      script.src = 'https://webforms.pipedrive.com/f/loader'
      script.id = 'formScript'
      document.body.appendChild(script)
    }
  }, [])

  return (
    <div className={classnames(styles.ticker, 'col-centered', '--narrow')}>
      <h2 className={styles.h2}>Esports Live Ticker</h2>
      <div id='shadow-h-ticker' className={styles.hTicker} />
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <p>
            Shadow Esports launched an unbiased and high quality live in-game
            data visualization product in 2021, the Esports Live Data
            Visualization (ELDV).
            <br />
            We're aiming to bring value to the community and to the esports
            fanbase.
          </p>
          <p>
            Boost the traffic on your Website and get a higher level on ROI.
            <br />
            Get your free license of our Esports Live Ticker{' '}
            <b
              className={styles.bold}
              onClick={() => {
                window.LeadBooster.trigger('open')
              }}
            >
              now
            </b>
            !
          </p>
          <p>
            <a href='https://www.bayesesports.com/events' target='_blank'>
              <strong>Check out our coverage</strong>
            </a>
          </p>
        </div>
        {/* <div id='shadow-v-ticker' className={styles.vTicker} /> */}
        <div
          class='pipedriveWebForms'
          data-pd-webforms='https://webforms.pipedrive.com/f/6cmpFyes5oYepKEYLkqntomLTN6z4p6lkV7bzSwLRIVmZ0iS9VmRqPlCkzQmFDs8P9'
        />
      </div>
    </div>
  )
}

export default Ticker
